.dashboard__secondModule {
  background-color: white;
  max-width: 900px;
  width: 100%;
  margin-top: 4rem;
  border-radius: 16px;
  .dashboard__secondModule__wrapper {
    padding: 3rem 3.2rem 3.2rem 3.2rem;
    .dashboard__secondModule__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      .dashboard__secondModule__header__title {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.8rem;
      }
      .dashboard__secondModule__header__btn {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: #351beb;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
