.tokenListTableBody {
  width: 100%;
}

.emptytokenList {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: #767993;
  margin-top: 48px;
  padding-bottom: 49px;
  border-bottom: 1px solid #e9ebf0;
  width: 100%;
}
