// typography

@mixin flex__column__center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin font__header__title {
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 3.4rem;
}

@mixin font-title {
  font-size: $font-size-s;
  font-weight: $font-weight-500;
}

@mixin font-note {
  font-size: 1.5rem;
  font-weight: $font-weight-400;
}

@mixin mainContentModal__base {
  background-color: $primary__white;
  max-width: 900px;
  width: 100%;
  border-radius: 16px;
  padding: 3.6rem 3.2rem;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
