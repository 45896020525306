.recentIssuedTokenItem_ul {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #181818;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e8e8e8;

  .recentIssuedTokenItem__li {
    margin-top: 6px;
    margin-bottom: 6px;
    &.hash {
      flex: 7%;
      a {
        cursor: pointer;
        span {
          &:hover {
            background-color: #d7dfff;
          }
          background: #f0f3ff;
          color: black;
          border-radius: 2px;
          padding: 5px 10px;
          width: 100%;
        }
      }
    }

    &.blockNum {
      font-weight: 600;
      flex: 2%;
    }

    &.time {
      color: #848484;
      flex: 1%;
    }
    &.fromTo {
      flex: 30%;
      align-items: center;
      display: flex;
      .recentIssuedTokenItem__li__fromto__from {
        flex: 1;
        text-align: center;
        a {
          cursor: pointer;
        }
        span {
          &:hover {
            background-color: #d7dfff;
          }
          color: black;
          background: #f0f3ff;
          padding: 5px 10px;
          border-radius: 2px;
        }
      }

      .recentIssuedTokenItem__li__fromto__to {
        flex: 1;
        border-radius: 2px;
        padding: 5px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          a {
            cursor: pointer;
          }
          &:hover {
            background-color: #dce0ee;
          }
          color: #5f5f6c;
          border: 1px solid #dce0ee;
          background: #ffffff;
          padding: 5px 10px;
          border-radius: 2px;
        }
      }
    }

    &.amount {
      flex: 1%;
      color: #b0b0b0;
      display: flex;
      justify-content: flex-end;
      .recentIssuedTokenItem__amount__wrapper {
        display: flex;
        .recentIssuedTokenItem__li__amount__num__dark {
          color: #5f5f6c;
        }
      }
    }
  }
}

.recentIssuedTokenItem__icon {
  margin-left: 6px;
  margin-right: 6px;
  width: 14px;
  height: 14px;
}

.recentIssuedTokenItem__fromto {
  &.to {
    color: #5f5f6c;
  }
}
