.deploy__header {
  .deploy__header__title {
    font-size: $font-size-xl;
    padding-bottom: 0.1rem;
    line-height: 3.4rem;
  }

  .deploy__header__text {
    color: $alert;
    font-size: $font-size-s;
    padding-bottom: 2.4rem;
    line-height: 2.2rem;
  }
}

button {
  cursor: pointer;
}
