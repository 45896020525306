.deployStep1__wrapper {
  display: flex;
  flex-direction: column;
  padding: 3.6rem 3.2rem;
  margin-top: 4rem;
  width: 100%;
  max-width: 900px;
  background-color: $primary__white;
  border-radius: 1.6rem;
  // position: relative;
  margin-bottom: 91px;
}

.deployStep1__inputTitle {
  margin-bottom: 1.4rem;
}

.tokenSetting {
  width: 25.3rem;
  border: 1px solid #4e4e54;
  border-radius: 16px;
  .tokenSetting__item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-left: 24px;
    font-size: $font-size-s;
    font-weight: 500;

    .tokenSetting__name {
      margin-right: 24px;
    }

    img {
      width: 17.58px;
      cursor: pointer;
      margin-left: 10.21px;
    }
  }

  & .tokenSetting__item__pause {
    padding-top: 28px;
  }

  & .tokenSetting__item__mint {
    margin-bottom: 28px;
    .tokenSetting__name {
      margin-right: 10px;
    }
  }
  &--active {
    margin-bottom: 38px;
  }
}

.deploy__limit__setting {
  display: none;

  .deploy__noLimit__wrapper {
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    position: relative;

    .deploy__noLimit__checkbox {
      display: none;
      box-sizing: border-box;
    }

    .deploy__noLimit__checkbox + label {
      border: 1px solid $gray07;
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-right: 1rem;
      box-sizing: border-box;
    }

    .deploy__noLimit__checkbox:checked + label::after {
      position: absolute;
      content: "✓";
      top: 1px;
      left: 0;
      height: 16px;
      width: 16px;
      color: white;
      font-size: 15px;
      text-align: center;
      background-color: black;
      line-height: 14px;
      border: 1px solid $gray07;
      box-sizing: border-box;
    }

    span {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
  &--active {
    display: block;
  }
}

.deployFee__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .deployFee__amount {
    @include font-title;
    display: flex;
    .deployFee__amount__title {
      margin-right: 30px;
    }
    div {
      :first-child {
        margin-right: 5px;
      }
    }
  }

  .deployFee__footNoteList {
    margin: 5.5rem 0 18.2rem 1rem;
    @include font-note;
    padding: 0;
    li {
      line-height: 28px;
    }

    & li:before {
      content: "•";
      font-size: 1.7rem;
      margin-right: 12px;
    }
    &--active {
      margin-bottom: 3.6rem;
    }
  }
  .deployFee__buttons {
    display: flex;
    justify-content: flex-end;

    & :first-child {
      margin-right: 16px;
    }
  }
}

.deploy {
  &.formInput__container {
    margin-top: 1.4rem;
  }
}
