.toggleSwitch__warpper {
  display: flex;
}

.react-switch {
  cursor: pointer;
  width: 6.2rem;
  height: 3rem;
  background: #dfdfdf;
  position: relative;
  border-radius: 40px;
  transition: background-color 0.2s;
  font-weight: 400;
  font-size: $font-size-xs;
  &.checked {
    background: black;
    color: white;
  }

  .react-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
  }
}

.react-switch .react-switch-button {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px;
  width: 2rem;
  height: 2rem;
  transition: 0.2s;
  border-radius: 100%;
  background: #000;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch {
  left: calc(100% - 5px);
  transform: translateX(-100%);
  background: white;
}

.react-switch-checkbox:checked + .react-switch-button {
  left: calc(100% - 5px);
  transform: translateX(-100%);
  background: white;
}

.react-switch-checkbox:active + .react-switch-button {
  width: 25px;
}

.react-switch-labels {
  // display: relative;
  // z-index: 0;
  // height: 100%;
  span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }

  span:first-child {
    left: 4px;
    & .checked {
      visibility: hidden;
    }
  }
  .toggleSwich__span__off {
    right: 4px;
  }

  & .checked {
    visibility: hidden;
  }
}
