.manageConfirmPopUp__background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  .manageConfirmPopUp {
    position: absolute;
    background-color: $primary__white;
    z-index: 10;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 408px;
    font-size: $font-size-m;
    font-weight: $font-weight-600;
    padding: 24px 0px 40px 0px;
    &.loading__extra__padding {
      // height: 234px;
    }

    .manageConfirmPopUp__exit {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      img {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 2.4rem;
      }
    }

    .manageConfirmPopUp__text {
      margin-top: 28px;
      text-align: center;
    }

    .manageConfirmPopUp__buttons {
      margin-top: 48px;
      display: flex;
    }
  }
}

.manageConfirmPopUp__spinner__wrapper {
  background-color: rebeccapurple;
}
