.dashboard__wrapper {
  @include flex__column__center;

  .dashboard__receipt__postision {
    position: relative;
    .dashboard__receipt__postision__ab {
      position: absolute;
      top: 4rem;
      right: -29rem;
      height: 100%;
      .fixed {
        position: -webkit-sticky;
        position: sticky;
        top: 8.1em;
      }
    }
  }
  .copyrightFooter__wrapper {
    margin-top: 24px;
    margin-bottom: 107px;
  }
}
