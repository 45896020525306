ul {
  list-style-type: none;
  padding-left: 0px;
}

.tokenListTableHead {
  display: flex;
  align-items: center;
  background-color: #000000;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border-radius: 8px;

  .tokenListTableHead__item {
    display: flex;
    justify-content: center;
  }

  .tokenListTableHead_number {
    width: 96px;
    padding: 10px 0;
    cursor: pointer;
    .tokenListTableHead__sortByNumber {
      display: flex;
      align-items: center;
      .arrowIcon {
        width: 17px;
      }
    }
  }
  .tokenListTableHead_tokenName {
    width: 196px;
  }
  .tokenListTableHead_symbol {
    width: 107px;
  }
  .tokenListTableHead_tokenAmount {
    width: 190px;
  }
  .tokenListTableHead_decimal {
    width: 98px;
  }

  .tokenListTableHead__select {
    position: relative;
    width: 149px;
    padding: 10px 0;
    cursor: pointer;
    select {
      display: none;
    }
  }
}

.selected {
  display: flex;
  justify-content: center;
  align-items: center;
  .selected-value {
    display: flex;
    justify-content: center;
    align-items: center;
    .arrowIcon {
      width: 18px;
    }
  }
}

.tokenListTableHead__select {
  ul {
    display: flex;
    width: 149px;
    position: absolute;
    top: 42px;
    background: #fff;
    border-top: none;
    border-radius: 8px;
    margin: 1px 0 0 -1px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
    display: none;

    &.active {
      display: flex;
    }
  }

  li {
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 22px;
    color: #000000;
    &:hover {
      color: #351beb;
    }
    &.activeFilter {
      background: #e9ebf0;
      border-radius: 8px;
      width: 141px;
      height: 38px;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
