.deployStep1__wrapper {
  display: flex;
  flex-direction: column;
  padding: 3.6rem 3.2rem;
  margin-top: 4rem;
  width: 100%;
  max-width: 900px;
  background-color: $primary__white;
  border-radius: 1.6rem;
  position: relative;
  margin-bottom: 91px;

  .deployStep1__business_information__wrapper {
    margin-top: 42px;
  }

  .deployStep1__buttonWrapper {
    display: flex;
    justify-content: flex-end;
  }
}
