//color
$primary__black: #000000;
$primary__white: #ffffff;
$primary__gray: #f5f5f6;
$gray01: #f7f7f8;
$gray06: #afaeba;
$gray07: #4e4e54;
$gray08: #4a4a4d;
$footer__gray__lighter: #aaacb6;
$footer__gray: #878997;
$footer__gray__background: #0b0c0f;

$alert: #767993;

//font size
$defalt-font-size: 10px;
$font-size-xs: 1.4rem;
$font-size-s: 1.6rem;
$font-size-m: 1.8rem;
$font-size-xl: 2.2rem;

//font-weight
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 500;
