.tokenInfoReceipt {
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 1.4s;
  animation-fill-mode: both;
  animation-name: slideDown;

  .tokenInfoReceipt_slideArrow {
    width: 20px;
  }
  .tokenInfoReceipt_img__wrapper {
    position: relative;
    width: 100%;
    max-width: 240px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .tokenInfoReceipt_img__text__wrapper {
      width: 100%;
      display: flex;
      position: absolute;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      transform: translateY(39px);
      animation-duration: 1s;
      animation-name: fadeIn;
      .tokenInfoReceipt__header__title {
        padding-top: 0.3rem;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.tokenInfoReceipt__status__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2.4rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 1.2rem;
  min-height: 4.2rem;

  span:first-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .tokenInfoReceipt__status {
    display: flex;
    align-items: center;

    .tokenInfoReceipt__status__circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 6px;
      &.active {
        background: #351beb;
      }

      &.inactive {
        background: #eb1b66;
      }
    }
  }
}

// todken info details
.tokenInfoReceipt__details__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 7rem;
  justify-content: flex-start;

  .tokenInfoReceipt__details_item {
    margin-bottom: 1.8rem;
    display: flex;
    justify-content: space-between;
    .tokenInfoReceipt__details_fix {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .tokenInfoReceipt__details_symbol__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tokenInfoReceipt__details_symbolImg {
        width: 24px;
        height: 24px;
        margin-right: 0.6rem;
      }
    }
    &--symbol {
      display: flex;
      align-items: center;
    }
  }
}

// todken info buttons
.tokenInfoReceipt__buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  margin-left: 10px;

  .tokenInfoReceipt_slideArrow {
    max-height: 2.5rem;
    padding: 4px 0;
    cursor: pointer;
    &--up {
      margin-bottom: 3.2rem;
    }
  }
}

.pageIndicator {
  display: flex;
  .pageIndicator__circle {
    padding: 4px;
    border-radius: 50%;

    &.active {
      background-color: #000000;
    }

    &.inactive {
      background-color: #b2b4bb;
    }

    &--left {
      margin-right: 16px;
    }

    &--center {
      margin-right: 16px;
    }
  }
}

.tokenInfoReceipt_imgAndIndicator__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tokenInfoReceipt__lastPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 13rem;
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
  }
  button {
    margin-top: 34px;
    width: 12.8rem;
    height: 44px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid $primary__black;
    color: $primary__black;
    a {
      text-decoration: none;
    }
    &:hover {
      background-color: $primary__black;
      color: $primary__white;
      border: 2px solid $primary__black;
      box-sizing: border-box;
    }

    cursor: pointer;
  }
}

//  amnimaion keyframes
@keyframes slideDown {
  0% {
    transform: translateX(0%) translateY(-10%);
  }
  to {
    top: 0px;
    transform: translateX(0%) translateY(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
