@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css");

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Pretendard Variable";
  background-color: $primary__gray;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  all: unset;
}
a {
  text-decoration: none;
}
html,
body,
#app,
#app > div {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

// date range picker
.rdrDateDisplayWrapper {
  display: none;
}

.rdrNextPrevButton {
  background-color: transparent !important;
}
