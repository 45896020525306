.tokenDetails__status__infoList {
  border: 1px solid #e9ebf0;
  border-radius: 8px;

  .tokenDetails__status__firstInfoList {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .tokenDetails__status__li {
    display: flex;
    align-items: center;
    padding: 0 8.7rem 0 2.4rem;
    width: 100%;

    &.copy {
      padding-right: 0;
    }

    .tokenDetails__status__Listkey {
      font-weight: 600;
      min-width: 160px;
    }
    .tokenDetails__status__ListValue {
      margin-left: 2rem;
      height: 40px;
      display: flex;
      align-items: center;
      &.copy {
        margin-right: 8px;
      }
    }
  }

  .tokenDetails__status__optionDivider {
    background: #f7f7f8;
    text-align: center;
    font-weight: 600;
    padding: 10px 0;
  }
  .tokenDetails__status__secondInfoList {
    margin: 20px 0px;
  }
}

.tokenDetails__copyIcon {
  width: 15px;
  height: 15px;
  &:hover {
    filter: brightness(0) saturate(100%) invert(21%) sepia(96%) saturate(7346%)
      hue-rotate(252deg) brightness(88%) contrast(112%);
  }
}

.tokenDetails__Copy__PopUp {
  background-color: #351beb;
  color: white;
  padding: 1px 4px;
  border-radius: 4px;
  font-size: 1.3rem;
}
