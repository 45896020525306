.tokenConnect__modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
}

.tokenConnect__modal {
  width: 48.8rem;
  background-color: $primary__white;
  border-radius: 1.6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 2;

  .tokenConnect__modal__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.4rem;
    padding: 2rem 2.4rem 1.6rem 2.4rem;
    img {
      width: 2rem;
    }
  }

  .tokenConnect__modal__main {
    background-color: $gray01;
    width: 44rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 2.4rem;
    color: $gray08;

    .tokenConnect__modal__initial {
      display: flex;
      flex-direction: column;
      align-items: center;

      span:first-child {
        margin-top: 4.8rem;
        font-size: $font-size-s;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36rem;
        height: 6.4rem;
        border-radius: 10px;
        background-color: $primary__white;
        margin: 4rem 0 4.8rem 0;

        &:hover {
          border: 4px solid #d1d9ff;
          box-sizing: border-box;
        }

        img {
          width: 1.6rem;
        }

        span {
          margin-left: 1rem;
          font-size: $font-size-s;
          font-weight: 600;
        }
      }
    }
  }
}

.tokenConnect__modal__uninstalled {
  font-size: $font-size-s;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  div:first-child {
    margin-top: 3.3rem;
    img {
      width: 1.6rem;
    }
    span {
      margin-left: 1rem;
      line-height: 28px;
    }
  }

  .tokenConnect__modal__instruction {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    a:first-child {
      margin: 1.4rem 0 0.3rem 0;
      text-decoration: none;
      color: $gray08;
      &:hover {
        text-decoration: underline;
      }
    }

    :nth-child(2) {
      margin: 0.3rem 0 3.2rem 0;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

.DateRangePickerModal__wrapper {
  position: relative;
}
