.walletConnectModal__background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.walletConnectModal__lottie {
  position: fixed;
  top: 80px;
  right: 60px;
}

.walletConnectModal {
  background: $primary__white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 160px;
  right: 8px;
  display: flex;
  align-items: center;
  padding: 30px 22px 24px 22px;
  border-radius: 10px;
  z-index: 20;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
}
