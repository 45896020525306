.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.CalendarDay {
  &.CalendarDay__default {
    border: 0px;
    color: black;
    &:hover {
      border: none;
    }
  }
}

.DateRangePickerModal {
  position: absolute;
  left: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary__white;
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  .DateRangePickerModal__buttonsWrapper {
    display: flex;
    margin-top: 10px;
    margin-bottom: 24px;
  }
}

.DayPicker__withBorder {
  box-shadow: none !important;
}

.DayPickerNavigation_button__default {
  border: none !important;
}

.CalendarDay__selected {
  background: #351beb !important;
  border: 0px !important;
}

.CalendarDay__selected_span {
  background: #d9e3ff !important;
}

.CalendarDay__selected_span {
  &:hover {
    color: black !important;
    background-color: #f7f7f7 !important;
    border: 0px !important;
  }
  &:active {
    color: black !important;
    background-color: #f7f7f7 !important;
    border: 0px !important;
  }
}

.CalendarDay__hovered_span {
  color: black !important;
  background-color: #f7f7f7 !important;
  border: 0px;
}
