.HomeThirdModule {
  color: $primary__white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20rem;
  .HomeThirdModule__img__wrapper {
    @include flex__column__center;
    position: relative;

    .HomeThirdModule__img__main_stone {
      position: absolute;
      width: 36rem;
      top: -4rem;
      right: 123rem;
    }

    .HomeThirdModule__img__ellipse_two {
      position: absolute;
      left: 70rem;
      transform: translate(0%, -33%);
    }
  }

  .HomeThirdModule__block__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 130rem;
    margin-top: 9.2rem;
    padding-bottom: 30rem;
    z-index: 2;

    .HomeThirdModule__block {
      display: flex;
      flex-direction: column;
      background: #14171b;
      border-radius: 16px;
      width: 100%;
      max-width: 40.8rem;
      padding: 4.8rem 1.8rem 4rem 4rem;

      .HomeThirdModule__block__header {
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 3.2rem;
      }
      .HomeThirdModule__block_preHeader {
        font-size: 18px;
        line-height: 32px;
      }
      img {
        margin-top: 3.2rem;
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

.HomeFourthModule {
  @include flex__column__center;
  background-color: $primary__black;
  color: $primary__white;
  margin-top: 11rem;
  margin-bottom: 24rem;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  z-index: 10;

  a {
    z-index: 10;
  }

  .HomeFourthModule__logo__wrapper {
    @include flex__column__center;
    margin-top: 1.3rem;
    z-index: 2;
    .HomeFourthModule__logo {
      margin-bottom: 3.8rem;
    }

    span {
      margin-bottom: 8rem;
    }
  }

  .HomeThirdModule__static__lottie_wrapper {
    position: relative;
    .group__left {
      position: absolute;
      transform: translate(-280%, -90%);
      left: 0px;
    }
    .group__right {
      position: absolute;
      transform: translate(374%, -91%);
      top: 0px;
      right: 0px;
    }
  }
}

.HomeFourthModule__scrollToTop {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  bottom: 40px;
  right: 44px;
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  cursor: pointer;
  img {
    cursor: pointer;
  }
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 14px, 0);
  }
}
