.deployPopUp {
  width: fit-content;
  border: 1px solid $primary__black;
  border-radius: 8px;
  padding: 1.5rem 1.6rem;
  font-size: 1.5rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  background-color: $primary__white;
  position: absolute;

  .deployPopUp__on {
    margin-bottom: d 1rem;
  }
  .deployPopUp__title {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &.pause {
    top: 450px;
    left: 238px;
  }

  &.burn {
    top: 520px;
    left: 238px;
  }

  &.mint {
    top: 590px;
    left: 260px;
  }
}
