.deploy__cotainer {
  @include flex__column__center;
  .deploy__component__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 940px;
    .deploy__stepBar__container {
      position: absolute;
      top: 380px;
      left: 0px;
    }
  }
}

.deploy__deployDone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyrightFooter__wrapper {
  margin-top: 2.3rem;
}
