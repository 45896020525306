.PageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary__black;
  height: 100vh;
  color: $primary__white;
  .PageNotFound__nav {
    width: 100%;
    padding: 2.3rem 2.4rem;
  }

  .PageNotFound__contents__wrapper {
    position: relative;
    margin-top: 6rem;
    .PageNotFound__text__wrapper {
      @include flex__column__center;
      position: absolute;
      top: 30%;
      left: 25%;
      h1 {
        font-weight: 700;
        font-size: 64px;
        line-height: 60px;
      }
      span {
        font-weight: 700;
        font-size: 32px;
        line-height: 60px;
      }

      .PageNotFound__button__wrapper {
        a {
          background: linear-gradient(
            138.81deg,
            #05ccf8 16.32%,
            #46bcff 24.37%,
            #5686f7 46.91%,
            #9239fa 80.28%,
            #ac2ff1 87.07%
          );
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }

        .PageNotFound__button__underline {
          top: 100%;
          width: 100%;
          left: 0;
          height: 1px;
          border-radius: 2px;
          background: linear-gradient(
            138.81deg,
            #05ccf8 16.32%,
            #46bcff 24.37%,
            #5686f7 46.91%,
            #9239fa 80.28%,
            #ac2ff1 87.07%
          );
        }
      }
    }
  }
}
