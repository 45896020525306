.totalAmountTextBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f8;
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  .totalAmountTextBox__amount {
    margin-left: 0.5rem;
  }
}
