.tokenInfo__carousels {
  background-color: #eef3ff;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  border-radius: 8px;
  max-width: 266px;
  max-height: 187px;
  position: relative;

  .tokenInfo__carousels__wrapper {
    display: flex;
    justify-content: space-between;

    .tokenInfo__carousels__titleBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 1.6rem;
      margin-bottom: 26px;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      img {
        width: 48px;
        height: 48px;
        margin-bottom: 0.8rem;
      }
    }

    .tokenInfo__carousels__numberBox {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 1.8rem;
      font-weight: 600;
      color: #351beb;
      animation-duration: 1.4s;
      animation-name: fadeIn;
      span:first-child {
        font-size: 2.2rem;
        color: black;
      }
    }
  }

  &--amount {
    background-color: #eef3ff;
  }

  &--holder {
    background-color: #f0f3e1;
  }

  &--price {
    background-color: #eafaff;

    .carousels__comming-soon {
      font-family: "Open Sans";
      font-style: italic;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: $primary__white;
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      text-align: center;
      padding: 0.6rem;
      position: absolute;
      top: 130px;
      left: 0px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
