.tokenListTableRow {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  flex: 1;
  border-bottom: 1px solid #e9ebf0;

  &:hover {
    cursor: pointer;
    background: #f7f7f8;
  }

  div {
    padding: 9px 0;
  }

  .tokenListTableRow__name {
    width: 96px;
    display: flex;
    justify-content: center;
  }

  .tokenListTableRow__tokenName {
    display: flex;
    justify-content: center;
    width: 196px;
  }

  .tokenListTableRow__symbol {
    display: flex;
    justify-content: center;
    width: 107px;
  }

  .tokenListTableRow__amount {
    display: flex;
    justify-content: center;
    width: 190px;
  }
  .tokenListTableRow__decimal {
    display: flex;
    justify-content: center;
    width: 98px;
  }

  .tokenListTableRow__timeStamp {
    display: flex;
    justify-content: center;
    width: 149px;
  }
}
