.HomeFirstModule {
  position: relative;
  height: calc(100vh);
  .HomeFirstModule__box {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: -8rem;
    right: 0px;
    z-index: 1;
    .home__spline__wrapper {
      position: relative;
      overflow-x: hidden;

      .HomeFirstModule_stars {
        width: 60%;
        position: absolute;
        top: 8rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: fadein 9s;
      }
    }
  }

  .home__spline__textBox {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $primary__white;
    width: 100%;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    h1 {
      font-weight: 700;
      font-size: 64px;
      line-height: 60px;
    }
    .home__spline__preHeader {
      @include flex__column__center;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      margin-top: 3.9rem;
      margin-bottom: 7rem;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spline__wrapper {
  overflow-y: hidden;
  height: 100vh;
  animation: fadein 10s;
}
