.footer {
  background-color: $footer__gray__background;
  display: flex;
  justify-content: flex-start;
  padding: 15.8rem 0rem 12rem 10%;
  .footer__wrapper {
    color: $footer__gray;
    font-size: $font-size-xs;
    display: flex;
    flex-direction: column;
    .footer__email {
      padding-left: 0.8rem;
      color: $primary__white;
    }
    .footer__copyright {
      padding-top: 2rem;
    }
  }
}
