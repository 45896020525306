.dashboard__firstModule {
  margin-top: 4rem;
  width: 100%;
  max-width: 900px;
  background-color: white;
  border-radius: 16px;
  padding: 32px;
  .dashboard__carousels {
    display: flex;
    border-radius: 16px;
    margin-bottom: 3.2rem;
    .IssuedTokenAmount__warpper {
      margin-right: 2rem;
    }
    .IssuedTokenHolder__warpper {
      margin-right: 2rem;
    }
  }

  .dashboard__dropdown__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 4rem;

    .dashboard__dropdown__linkAndDateFilter__wrapper {
      display: flex;
      align-items: center;
      a {
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 14px;
        color: #4e4e54;
        margin-right: 2rem;
        text-decoration: underline;
      }
    }
    .dashboard__dropdown__symbol {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        margin-right: 0.8rem;
        animation-duration: 1.4s;
        animation-name: fadeIn;
        img {
          margin-right: 0.8rem;
        }
      }
    }

    .dashboard__dropdown__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 13px 8px 23px;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 22px;
      color: #4a4a4d;
      border: 1px solid #7c7c7e;
      border-radius: 8px;
      img {
        margin-left: 1.5rem;
        width: 1.7rem;
        margin-bottom: 0.3rem;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
