.aergoConnectInstallModal {
  display: flex;
  background: $primary__white;
  flex-direction: column;
  position: fixed;
  top: 90px;
  right: 8px;
  display: flex;
  align-items: center;
  padding: 17px 22px 24px 22px;
  border-radius: 10px;
  z-index: 20;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  .aergoConnectInstallModal__exit {
    width: 100%;
    text-align: end;
    button {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  button {
    font-size: 14px;
    color: #4a4a4d;
  }
}
