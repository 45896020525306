.manageHome {
  @include mainContentModal__base;
  padding: 3.6rem 3.2rem;

  .manageHome__contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 520px;
    .manageHome__available__box {
      text-align: center;
      background: #f7f7f8;
      padding: 1rem 21rem;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.8rem;
    }
    .manageHome__buttons_box {
      width: 100%;
      max-width: 900px;
      margin-top: 2.4rem;
      display: flex;
      justify-content: space-around;
    }
    .manageHome__note {
      margin-top: 2.4rem;
      font-size: 1.5rem;
      color: #767993;
    }

    .manageHome__previousBtn {
      margin-top: 48px;
    }
  }
}

.manageHome__noOption {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.8rem;
}
