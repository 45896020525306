// spinner
.spinner__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $primary__gray;
}

.spinner__center__wrapper {
  width: 100%;
  height: 85vh;
  position: absolute;
}

.spinner {
  width: 18px;
  height: 18px;
  border: 4px solid transparent;
  border-bottom-color: #000000;
  border-radius: 100%;
  box-sizing: border-box;
  animation: rotation 0.8s linear infinite;
  backdrop-filter: blur(10px);

  &.x-small {
    width: 20px;
    height: 20px;
  }
  &.small {
    width: 24px;
    height: 24px;
  }
  &.medium {
    width: 28px;
    height: 28px;
  }
  &.large {
    width: 32px;
    height: 32px;
  }
  &.x-large {
    width: 36px;
    height: 36px;
  }
  &.center {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 70%;
    text-align: center;
    // z-index: 10;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
