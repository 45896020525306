.nav__wrapper {
  z-index: 40;
  position: sticky;
  top: 0px;
}

.nav {
  display: flex;
  align-items: center;
  box-shadow: 0rem 0.2rem 1rem rgba(0, 0, 0, 0.1);
  height: 8rem;
  background-color: $primary__white;
  .nav__logo {
    padding: 2.4rem 2rem;
    flex: 1;
  }
  .nav__list {
    display: flex;
    justify-content: center;
    font-size: $font-size-s;
    font-weight: 600;
    flex: 1;
    margin-right: 4rem;
    .nav__item--active {
      color: $primary__black;
    }
    .nav__item {
      color: $gray06;
    }
    .nav__item__center {
      margin: 0 4rem;
    }
  }
  .nav__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav__aergo {
      width: 4rem;
      height: 4rem;
      margin-right: 1.6rem;
    }
    .nav__connect {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.2rem solid #dee2ff;
      font-size: 1.4rem;
      font-weight: 600;
      border-radius: 8px;
      text-align: center;
      width: 13.8rem;
      height: 4.2rem;
      margin-right: 24px;
      box-sizing: border-box;

      &--on {
        background-color: #dee2ff;
        color: $primary__black;
        border: 2px solid #c5cbfb;
      }

      &--off {
        color: $primary__black;
      }

      &:hover {
        border: 2px solid #c5cbfb;
        background: #dee2ff;
      }
    }
  }
}

.nav--home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(25, 27, 31, 0.1) 0%,
    rgba(15, 16, 17, 0.1) 100%
  );
  background-blend-mode: multiply;
  backdrop-filter: blur(7px);
  height: 8rem;
  .nav__logo {
    padding: 2.4rem 2rem;
  }
  .nav__list {
    display: none;
    font-size: $font-size-s;
    font-weight: 600;
    .nav__item--active {
      color: $primary__black;
      margin-left: 4rem;
    }
    .nav__item {
      margin-left: 4rem;
      color: $gray06;
    }
  }
  .nav__buttons {
    display: flex;
    align-items: center;
    .nav__aergo {
      width: 4rem;
      height: 4rem;
      margin-right: 1.6rem;
      display: none;
    }

    .nav__connect {
      font-size: 1.4rem;
      font-weight: 400;
      text-align: center;
      width: 13.8rem;
      height: 4.2rem;
      margin-right: 24px;
      color: #7569ff;
      border-radius: 8px;
      background: rgba(117, 105, 255, 0.2);

      &:hover {
        border: 2px solid #7569ff;
        box-shadow: 0px 0px 20px rgba(117, 105, 255, 0.8);
        box-sizing: border-box;
      }
    }
  }
}
