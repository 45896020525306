.tokenDetails {
  padding: 3.2rem 3.6rem;
  max-width: 90rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: white;
  margin-top: 4rem;
  margin-bottom: 6.5rem;
  .tokenDetails__title {
    @include font__header__title;
  }
  .tokenDetails__buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;
  }
}

.tokenDetails__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.2rem;
  .tokenDetails__mainContainer {
    display: flex;
    .tokenDetails__status {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 48px;

      .tokenDetails__status__circle__column {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .tokenDetails__status__circle {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 6px;

          &--active {
            background: #351beb;
          }

          &--pause {
            background: #eb1b66;
          }
        }
      }
    }
  }
}
