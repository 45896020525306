.HomeThirdModule__grandient__element__wrapper {
  position: relative;
  overflow: hidden;
  .HomeThirdModule__grandient__element {
    margin-top: 0rem;
    left: 50%;
    transform: translate(-50%, -18%);
    position: absolute;
    z-index: 0;
  }
}
.Home__secondThird__wrapper {
  position: relative;
  overflow-x: hidden;
  .homeSecondModule__imgs__wrapper {
    .homeSecondModule__imgs__inner__wrapper {
      position: relative;
      .homeSecondModule__box__gra1__img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(3%, -62%);
        z-index: 1;
      }

      .HomeThirdModule__img__ellipse_two {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(50%, -10%);
        z-index: 1;
      }
    }
  }
}
