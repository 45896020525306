.decimal {
  color: $primary__white;
  display: flex;
  justify-content: space-between;
  position: relative;
  .decimal__input {
    width: 100%;
    height: 4.4rem;
    border-radius: 8px;
    border: 1px solid #4e4e54;
    text-align: center;
  }
  button {
    width: 1rem;
    background: #272727;
    border-radius: 8px;
    width: 6.4rem;
    text-align: center;
    position: absolute;
    &.decimal__button--minus {
      left: 0;
      height: 100%;
      font-size: 2rem;
    }
    &.decimal__button--plus {
      right: 0;
      height: 100%;
      font-size: 2rem;
    }
  }
}
