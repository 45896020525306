.HomeSecondModule {
  background-color: $primary__black;
  color: $primary__white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30rem;
  overflow-x: hidden;
  padding-bottom: 10rem;
  .HomeSecondModule__imgBox {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-130%, -145%);
    margin-bottom: 9rem;
    margin-left: 8.5rem;
    img {
      width: 70%;
    }
  }

  .HomeSecondModule__contents__wrapper {
    width: 100%;
    max-width: 100rem;
    position: relative;
    z-index: 2;

    .HomeSecondModule__contents__imgHeaderWrapper {
      .HomeSecondModule__contents__imgHeaderContainer {
        display: flex;
        justify-content: space-between;
        .HomeSecondModule__contents__gradient__h1 {
          font-weight: 700;
          font-size: 5.6rem;
          line-height: 6rem;
          background: linear-gradient(
            115.63deg,
            #ffffff 13.77%,
            #bbd6ff 78.92%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
        .HomeSecondModule__contents__headline__text {
          margin-top: 6.4rem;
          font-size: 18px;
          line-height: 32px;
          span {
            font-weight: 700;
          }
        }
      }
    }
  }

  .homeSecondModule__point__text__box {
    display: flex;
    justify-content: space-between;
    margin-top: 13.3rem;
    div {
      @include flex__column__center;
    }

    .homeSecondModule__point__text__item {
      margin-bottom: 26.6rem;
      position: relative;

      .homeSecondModule__box__edge2__img {
        top: 24rem;
        left: 30rem;
        position: absolute;
      }
    }
    .homeSecondModule__text__box__gradient__span {
      font-weight: 800;
      font-size: 86px;
      line-height: 90px;
      background: linear-gradient(115.63deg, #dbe9ff 13.77%, #bbd6ff 78.92%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: -0.03em;
      background-clip: text;
    }

    .homeSecondModule__text__box__span {
      margin-top: 2.5rem;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #afaeba;
    }
  }
}
