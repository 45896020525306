.RecentIssuedTokenList__noTokenList {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 28px;
  color: #767993;
  margin-top: 48px;
  padding-bottom: 48px;
  border-bottom: 1px solid #e8e8e8;
}
