.divider {
  border: 1px solid #e9ebf0;
  border-radius: 5px;
  width: 100%;
}

//input
.formInput__container {
  .formInput {
    width: 100%;
    padding: 1.1rem 1.6rem;
    border-radius: 0.8rem;
    background-color: $primary__white;
    border: 1px solid #4e4e54;
    &.formInput__validation {
      border: 1px solid #eb1b66;
    }
    &:hover {
      outline: 4px solid #d1d9ff;
      cursor: pointer;
    }

    &:focus {
      outline: 4px solid #d1d9ff;
    }

    &::placeholder {
      color: #b2b4bb;
    }

    &:disabled {
      background: #f7f7f8;
      color: #8d8f9e;
      outline: none;
    }
  }
}

.formInput__text__wrapper {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-xs;
  font-weight: 500;

  .formInput__title {
    @include font-title;
  }

  .formInput__option {
    margin-left: 0.4rem;
    font-size: $font-size-xs;
    color: $alert;
  }

  .formInput__validationTextOff {
    color: $alert;
  }

  .formInput__validationTextOn {
    color: #eb1b66;
  }
}

//button
.commonButton {
  width: 12.8rem;
  height: 44px;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 28px;
  text-align: center;
  border-radius: 8px;
  color: $primary__white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.black {
    background-color: $primary__black;
  }

  &.white {
    background-color: $primary__white;
    border: 1px solid $primary__black;
    color: $primary__black;
  }
  &.transparent {
    background-color: transparent;
    border: 1px solid $primary__white;
    color: $primary__white;
    line-height: 29px;
    font-size: 1.8rem;
    &:hover {
      background-color: $primary__white;
      color: $primary__black;
      border: 1px solid transparent;
    }
  }
  &:hover {
    background-color: $primary__white;
    color: $primary__black;
    border: 2px solid $primary__black;
    box-sizing: border-box;
  }

  &.manage {
    width: 16rem;
    height: 4.4rem;
  }
}

//step bars
.stepIndicator__wrapper {
  display: flex;
  flex-direction: column;

  .stepBar--off {
    border: 0.2rem solid #d7d7d7;
    max-width: 0.2rem;
    box-sizing: border-box;
    background: #d7d7d7;
    height: 3.6rem;
  }
  & .stepBar--on {
    border: 0.2rem solid $primary__black;
    max-width: 0.2rem;
    box-sizing: border-box;
    background: $primary__black;
    height: 3.6rem;
  }
}

// copyright footer
.CopyrightFooter {
  font-size: 1.1rem;
  color: $footer__gray__lighter;
}
