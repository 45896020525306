.walletChangeModal {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 1rem;
  top: 10rem;
  z-index: 20;
  background: $primary__white;
  width: 100%;
  max-width: 286px;
  padding: 1.2rem 1.6rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  justify-content: center;
  align-items: center;

  .walletChangeModal__title__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    position: relative;
    width: 100%;
    text-align: center;

    .walletChangeModal__title {
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      color: #7681bc;
      width: 100%;
    }

    .walletChangeModal__title__btn {
      position: absolute;
      right: 0px;
      width: 10%;
      img {
        cursor: pointer;
        width: 1.8rem;
      }
    }
  }

  .walletChangeModal__copyArea {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
    width: 100%;
    margin-left: 4rem;

    span {
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      text-align: end;
      color: #4e4e54;
      flex: 2;
      margin-right: 1rem;
    }
    .walletChangeModal__icon__container {
      flex: 1;
      justify-content: flex-start;
      align-items: flex-start;
      button {
        .walletChangeModal__copyIcon {
          width: 16px;
        }
        cursor: pointer;

        img {
          &:hover {
            filter: brightness(0) saturate(100%) invert(21%) sepia(96%)
              saturate(7346%) hue-rotate(252deg) brightness(88%) contrast(112%);
          }
        }
      }

      .walletChangeModal__copy__popUp {
        background-color: #351beb;
        color: white;
        padding: 4px 4px;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        border-radius: 4px;
        font-size: 1.3rem;
      }
    }
  }

  .walletChangeModal__button {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #b2b4bb;
    cursor: pointer;
  }
}
