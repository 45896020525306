.CarouselHighLights__container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 900px;

  &--left {
    margin-right: 20px;
    flex: 1;
  }

  &--right {
    flex: 1;
  }
}
