.MuiTable-root {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.tableHeadIcon {
  width: 14px;
  height: 14px;
}

.tableCellIcon {
  width: 14px;
  height: 14px;
}

.dashboard__table__color {
  background-color: #f0f3ff;
  text-align: center;
  padding: 5px 10px;
}

.MuiTableCell-root .MuiTableHead-root {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
}
