.RecentIssuedTokenListHeader {
  border-bottom: 1px solid #7c7c7e;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 8px;
  .RecentIssuedTokenListHeader__list {
    display: flex;
  }
  .RecentIssuedTokenListHeader__li {
    &.hash {
      flex: 7%;
    }

    &.block {
      flex: 2%;
    }

    &.time {
      flex: 1%;
    }

    &.fromTo {
      flex: 30%;
      align-items: center;
      display: flex;
      .RecentIssuedTokenListHeader__li__fromTo__from {
        flex: 1;
        text-align: center;
      }
      .RecentIssuedTokenListHeader__li__fromTo__to {
        flex: 1;
        text-align: center;
      }
      .recentIssuedTokenItem__icon__wrapper {
        flex: 1;
        img {
          margin-left: 45px;
          margin-right: 54px;
        }
      }
    }

    &.amount {
      flex: 1%;
      text-align: end;
    }
  }
}
