.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 16px;
  margin-top: 34px;

  button {
    cursor: pointer;
  }

  .arrowIcon {
    width: 20px;
    height: 20px;
  }

  .pagination__item {
    padding: 4px 9px;
    margin-bottom: 2px;

    &.active {
      background: #000000;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 16px;
      color: #ffffff;

      text-align: center;
      border-radius: 50%;
      margin: 0 10px;
    }
  }
}

//  className={`arrowIcon ${currentPage === 1 ? "inactive" : ""}`}
// className={`arrowIcon ${
//   currentPage === totalPage.length ? "inactive" : ""
// }`}
