.managePause {
  @include mainContentModal__base;

  .tokenDetails__status__circle__column {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    .tokenDetails__status__circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 6px;

      &--active {
        background: #351beb;
      }

      &--pause {
        background: #eb1b66;
      }
    }
  }
  .managePause__contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .managePause__status__indicator {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e9ebf0;
      border-radius: 8px;
      width: 100%;
      max-width: 52rem;
      padding: 0.8rem;
      margin-bottom: 2.4rem;
      line-height: 3rem;
    }
    .managePause__notes {
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      display: flex;
      flex-direction: column;
      margin-bottom: 5.5rem;
      span {
        display: inline-block;
      }
    }
    .managePause__buttonsWrapper {
      display: flex;
      width: 100%;
      max-width: 520px;
      justify-content: flex-end;
    }
  }
}
