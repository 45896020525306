.symbolMaker__wrapper {
  display: flex;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.8rem;

  .symbolImg__box {
    background-color: rebeccapurple;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 10rem;
    max-width: 10rem;
  }

  .symbolMaker__controller__box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 4rem;

    .symbolMaker__shaper__box {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .symbolMaker__shaper__ul {
        display: flex;
        align-items: center;
        li {
          margin-left: 2.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .symbolMaker__borderSeletor__box {
      margin-left: 7.9rem;
      display: flex;
      align-items: center;
      span {
        margin-right: 2.4rem;
      }

      .symbolMaker__borderSeletor__input__box {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        input {
          margin-right: 1rem;
          border: none;
          outline: none;
          padding: 0;
          -webkit-appearance: none;
          width: 3.4rem;
          height: 2rem;
          margin: 0;
          background-color: transparent;
          cursor: pointer;
          &::-webkit-color-swatch-wrapper {
            padding: 0;
          }

          &::-webkit-color-swatch {
            border: 1px solid #7e7e7e;
            border-top-left-radius: 0.4rem;
            border-bottom-left-radius: 0.4rem;
          }
        }
      }

      .symbolMaker__borderSeletor__colorPicker__wrapper {
        position: relative;
        .symbolMaker__borderSeletor__colorPicker__box {
          display: flex;
          border: 1px solid #7e7e7e;
          border-left: none;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding: 4.5px 3px;
        }

        span {
          position: absolute;
          left: -22px;
          top: 24px;
          font-weight: 500;
          font-size: 1.1rem;
          line-height: 1.3rem;
        }
      }

      .symbolMaker__borderSeletor__noBorderBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  // img uploader

  .symbolMaker__imgUploader__wrapper {
    display: flex;
    margin-top: 3.7rem;
    .symbolMaker__imgUploader__controller {
      display: flex;
      margin-left: 2.1rem;
      .symbolMaker__caremraIcon {
        margin-right: 2.4rem;
      }
    }

    .symbolMaker__imgUploader__imgDeleteBtn__wrapper {
      display: flex;
      align-items: center;
      margin: 0.2rem 0 0.4rem 0;
    }
    .symbolMaker__imgUploader__imgName {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.2rem;
      margin-right: 0.7rem;
    }
    .symbolMaker__imgUploader__note {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.2rem;
      display: flex;
      align-items: center;
      color: #767993;
    }

    .symbolMaker__imgUploader__box {
      display: flex;
      align-items: flex-start;
      margin-left: 8.4rem;
      .symbolMaker__imgUploader__input__box {
        display: flex;
        margin-left: 2.4rem;
        .symbolMaker__borderSeletor__borderColor__wrapper {
          position: relative;
          .symbolMaker__borderSeletor__borderColor__box {
            display: flex;
            border: 1px solid #7e7e7e;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding: 4.5px 3px;
          }
          span {
            position: absolute;
            left: -22px;
            top: 24px;
            font-weight: 500;
            font-size: 1.1rem;
            line-height: 1.3rem;
          }
        }

        .symbolMaker__borderSeletor__noBorderBtn {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }

      input {
        margin-right: 1rem;
        border: none;
        outline: none;
        padding: 0;
        -webkit-appearance: none;
        width: 3.4rem;
        height: 2rem;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        &::-webkit-color-swatch-wrapper {
          padding: 0;
        }

        &::-webkit-color-swatch {
          border: 1px solid #7e7e7e;
          border-right: none;
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }
      }
    }
  }

  .symbolMaker__svgStroke__box {
    padding: 1rem;
    padding-bottom: 1.5px;
    border: 1px solid #4e4e54;
    border-radius: 8px;

    &.symbolMaker__svgStroke__box__validation {
      border: 1px solid #eb1b66;
    }
  }
}
