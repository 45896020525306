.manageMint {
  @include mainContentModal__base;

  .manageMint__contents {
    width: 100%;
    max-width: 520px;
    .manageMint__input__wrapper {
      margin: 20px 0 30px 0;
      .validationWarnning {
        font-weight: 500;
        font-size: 15px;
        line-height: 28px;
        color: #eb1b66;
        margin-top: 10px;
      }
    }
    .manageMint__buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
}
