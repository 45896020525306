.TokenGeneration__wrapper {
  margin-top: 4rem;

  .TokenGeneration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary__white;
    border-radius: 16px;
    box-sizing: border-box;

    .TokenGeneration__img {
      margin-top: 8.7rem;
      width: 16rem;
    }

    .TokenGeneration__lottie__wrapper {
      z-index: 1;
      position: absolute;
      top: 150px;
    }

    span {
      font-size: 1.4rem;
      margin-top: 3rem;
    }

    .TokenGeneration__btn {
      width: 22rem;
      height: 4.6rem;
      background-color: $primary__black;
      border-radius: 2.3rem;
      color: $primary__white;
      text-align: center;
      font-size: 1.6rem;
      margin: 6.4rem 34rem 9.5rem 34rem;
      box-sizing: border-box;

      &:hover {
        background-color: $primary__white;
        color: $primary__black;
        border: 2px solid $primary__black;
      }
    }
  }
}
