.CarouselHighLight {
  display: flex;
  max-width: 44rem;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 21px 32px;
  flex-direction: column;
  .CarouselHighLight__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .CarouselHighLight__title {
      flex-direction: row;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.8rem;
    }

    .CarouselHighLight__number {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }

  .CarouselHighLight__status {
    margin-top: 7px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .CarouselHighLight__status__arrow {
      width: 10px;
      height: 10px;
    }
    .CarouselHighLight__status__number {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      &--up {
        color: #351beb;
      }
      &--down {
        color: #eb1b66;
      }
    }
  }
}
